.custom-shape-divider-top-1664360236 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1664360236 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 40px;
}

.custom-shape-divider-top-1664360236 .shape-fill {
  fill: #585858;
}
